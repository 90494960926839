import { Route, Routes } from 'react-router-dom';
import HasPermission from '../../../base/components/UserProvider/HasPermission';
import {
  CompanyStatus,
  UserPermission,
} from '../../../graphql/graphql-operations';
import NotFoundDetail from '../../components/NotFoundDetail/NotFoundDetail';
import CompaniesPage from './CompaniesPage';
import CompanyApiKeysPage from './CompanyApiKeysPage';
import CompanyDetailPage from './CompanyDetailPage';
import CompanyDevicesPage from './CompanyDevicesPage';
import CompanyFeaturesPage from './CompanyFeaturesPage';
import CompanyFilesPage from './CompanyFilesPage';
import CompanyQRScenesPage from './CompanyQRScenesPage';
import CompanyQrsPage from './CompanyQRsPage';
import CompanyScenesPage from './CompanyScenesPage';
import CompanyUserGroupsPage from './CompanyUserGroupsPage';
import CompanyVrBoxesPage from './CompanyVrBoxesPage';

const CompaniesRoute = () => (
  <Routes>
    <Route
      index
      element={<CompaniesPage companyStatus={CompanyStatus.ACTIVE} />}
    />
    <Route path="/:companyId" element={<CompanyDetailPage />} />
    <Route
      path="/suspended"
      element={<CompaniesPage companyStatus={CompanyStatus.SUSPENDED} />}
    />

    <Route path="/:companyId/scenes" element={<CompanyScenesPage />} />
    <Route path="/:companyId/features" element={<CompanyFeaturesPage />} />
    <Route path="/:companyId/boxes" element={<CompanyVrBoxesPage />} />
    <Route path="/:companyId/devices" element={<CompanyDevicesPage />} />
    <Route path="/:companyId/user-groups" element={<CompanyUserGroupsPage />} />
    <Route
      path="/:companyId/api-keys"
      element={
        <HasPermission val={UserPermission.MANAGE_COMPANY_API_KEYS}>
          <CompanyApiKeysPage />
        </HasPermission>
      }
    />

    <Route
      path="/:companyId/qrs"
      element={
        <HasPermission val={UserPermission.MANAGE_COMPANY_QRS}>
          <CompanyQrsPage />
        </HasPermission>
      }
    />
    <Route
      path="/:companyId/qr-scenes"
      element={
        <HasPermission val={UserPermission.MANAGE_COMPANY_QRS}>
          <CompanyQRScenesPage />
        </HasPermission>
      }
    />

    <Route
      path="/:companyId/files"
      element={
        <HasPermission val={UserPermission.MANAGE_COMPANY_FILES}>
          <CompanyFilesPage />
        </HasPermission>
      }
    />

    <Route path="*" element={<NotFoundDetail />} />
  </Routes>
);

export default CompaniesRoute;
