import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ApplicationSceneGroupStatus } from '../../../graphql/graphql-operations';
import { SectionPaper } from '../../components/SectionPaper';
import { TopBar } from '../../components/TopBar';
import ApplicationGroupsTable from './ApplicationGroupsTable';

const ApplicationGroupsSuspendedPage: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <>
      <TopBar
        leftSideText={t(
          'applicationGroups.topBar.suspendedTitle',
          'Archived programs',
        )}
        actions={
          <Button
            id="application-groups-suspended-page-active"
            size="large"
            color="primary"
            variant="text"
            component={Link}
            to={'/admin/applications'}
          >
            {t(`applicationGroups.activeApplications`, 'Active Programs')}
          </Button>
        }
      />
      <SectionPaper>
        <ApplicationGroupsTable
          status={ApplicationSceneGroupStatus.SUSPENDED}
        />
      </SectionPaper>
    </>
  );
};

export default ApplicationGroupsSuspendedPage;
