import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { CardContent, IconButton, Typography } from '@mui/material';
import { BaseCard } from '../../../../base/components/BaseCard';
import { Flex } from '../../../../base/components/Flex';
import {
  CompanyFilesQuery,
  CompanyFileType,
} from '../../../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';

interface Props {
  file: CompanyFilesQuery['companyFiles'][0];
  onInvalidateClick: () => void;
}

const CompanyFileCard = ({ file, onInvalidateClick }: Props) => {
  const { _t, t } = useTranslationPrefix('CompanyFilesPage');

  return (
    <BaseCard>
      <CardContent>
        <Flex alignItems="flex-start">
          <Flex
            flexDirection="column"
            alignItems="center"
            sx={{ width: '24rem' }}
          >
            {file.type === CompanyFileType.IMAGE && (
              <img
                id={`company-file-${file.id}`}
                src={file.url}
                alt={file.name}
                style={{ width: '100%' }}
              />
            )}
            {/*For now only images supported*/}
          </Flex>

          <Flex
            display="grid"
            gridTemplateColumns="repeat(2, auto)"
            gap={1}
            p={2}
          >
            <Typography color="grey.500">{_t('name', 'Name:')}</Typography>
            <Typography>{file.name}</Typography>

            <Typography color="grey.500">{_t('type', 'Type:')}</Typography>
            <Typography>
              {t(`enum.CompanyFileType.${file.type}`, file.type)}
            </Typography>

            <Typography color="grey.500">
              {_t('company', 'Company:')}
            </Typography>
            <Typography>{file.company.name}</Typography>
          </Flex>
          <Flex flexGrow={1} justifyContent="flex-end" gap={1}>
            <IconButton
              id={`invalidate-file-${file.id}`}
              onClick={onInvalidateClick}
              color="error"
            >
              <DeleteRoundedIcon />
            </IconButton>
          </Flex>
        </Flex>
      </CardContent>
    </BaseCard>
  );
};

export default CompanyFileCard;
