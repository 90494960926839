import { useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  GetAvailableHelpFaqDocument,
  GetAvailableHelpFaqQuery,
  SendHelpFaqResultDocument,
} from '../../../graphql/graphql-operations';
import {
  HelpFaqButtonType,
  HelpFaqContentButton,
  HelpFaqContentItemType,
  HelpFaqContentSlide,
} from './types';

// Record<name, value>
type SlideValues = Record<string, string | number | boolean>;

interface HelpFaqContextType {
  canMoveOn: boolean;
  helpFaqData?: GetAvailableHelpFaqQuery | null;
  activeSlide: HelpFaqContentSlide | null;
  goNext: (nextSlideId: number) => void;
  goBack: () => void;
  step: number;
  onConfirm: (nextSlideId: number) => void;
  onButtonClick: (buttonInput: HelpFaqContentButton) => void;
  updateValue: (name: string, value: string | number | boolean) => void;
}

export const HelpFaqContext = React.createContext<HelpFaqContextType>({
  canMoveOn: true,
  helpFaqData: null,
  onButtonClick: (_: HelpFaqContentButton) => {},
  goNext: (_: number, __?: SlideValues) => {},
  step: 1,
  goBack: () => {},
  onConfirm: () => {},
  updateValue: (name: string, value: string | number | boolean) => {},
  activeSlide: null,
});

interface Props {
  children?: React.ReactNode;
}

interface HelpFaqProgress {
  slideId: number;
  // Record<name, value>
  values?: SlideValues;
}

const findActiveSlide = (slide: HelpFaqContentSlide, activeSlideId: number) => {
  if (slide.id === activeSlideId) {
    return slide;
  }

  const slidesWithButtons = slide.items?.flatMap((item) =>
    item.type === HelpFaqContentItemType.CHOICES ? item : [],
  );

  let result: HelpFaqContentSlide | null = null;
  slide.buttons?.forEach((slideButton) => {
    if (
      (slideButton.type === HelpFaqButtonType.NEXT ||
        slideButton.type === HelpFaqButtonType.CONFIRM) &&
      result == null
    ) {
      result = findActiveSlide(slideButton.next_slide, activeSlideId);
    }
  });

  if (result != null) {
    return result;
  }

  slidesWithButtons?.forEach((slide) => {
    slide.buttons?.forEach((button) => {
      if (
        [HelpFaqButtonType.NEXT, HelpFaqButtonType.CONFIRM].includes(
          button.type,
        ) &&
        result == null
      ) {
        result = findActiveSlide(button.next_slide, activeSlideId);
      }
    });
  });
  return result;
};

const HelpFaqProvider = ({ children }: Props) => {
  const [helpFaqProgress, setHelpFaqProgress] = React.useState<
    HelpFaqProgress[]
  >([]);

  const navigate = useNavigate();

  const [sendHelpFaqResult] = useMutation(SendHelpFaqResultDocument);

  const { data: helpFaqData } = useQuery(GetAvailableHelpFaqDocument, {
    onCompleted: (data) => {
      if (data?.availableHelpFaq.content_t && helpFaqProgress.length < 1) {
        const dataFaqContent = JSON.parse(
          data?.availableHelpFaq.content_t,
        ) as HelpFaqContentSlide;
        setHelpFaqProgress([{ slideId: dataFaqContent.id }]);
      }
    },
  });

  const handleGoBack = () => {
    if (helpFaqProgress.length > 1) {
      setHelpFaqProgress((recentProgress) => recentProgress.slice(0, -1));
    }
  };

  const activeSlideId = helpFaqProgress[helpFaqProgress.length - 1]?.slideId;
  const updateRecentSlide = (values: SlideValues) =>
    setHelpFaqProgress((recentProgress) =>
      recentProgress.map((slide) =>
        slide.slideId === activeSlideId
          ? { ...slide, values: { ...(slide.values || {}), ...values } }
          : slide,
      ),
    );

  const handleGoNext = (nextSlideId: number) => {
    setHelpFaqProgress((recentProgress) => [
      ...recentProgress,
      { slideId: nextSlideId },
    ]);
  };

  const handleConfirm = (nextSlideId: number) => {
    sendHelpFaqResult({
      variables: {
        sendHelpFaqResult: {
          helpFaqId: helpFaqData!.availableHelpFaq.id!,
          result: helpFaqProgress.map((slide) => ({
            slideId: slide.slideId.toString(),
            values: slide.values
              ? Object.keys(slide.values).map((name) => ({
                  name,
                  value: slide.values![name].toString()!,
                }))
              : undefined,
          })),
        },
      },
      onCompleted: () => {
        handleGoNext(nextSlideId);
      },
    });
  };

  const handleButtonClick = (buttonInput: HelpFaqContentButton) => {
    if (buttonInput.type === HelpFaqButtonType.NEXT) {
      handleGoNext(buttonInput.next_slide.id);
    } else if (buttonInput.type === HelpFaqButtonType.BACK) {
      handleGoBack();
    } else if (buttonInput.type === HelpFaqButtonType.CONFIRM) {
      handleConfirm(buttonInput.next_slide.id);
    } else if (buttonInput.type === HelpFaqButtonType.LEAVE) {
      navigate('/');
    }
  };

  const getRequiredInputsFromSlide = (slide: HelpFaqContentSlide) => {
    const inputs = slide.items?.flatMap((item) =>
      item.type === HelpFaqContentItemType.TEXT_AREA ||
      item.type === HelpFaqContentItemType.TEXT_INPUT
        ? item
        : [],
    );
    return (inputs || []).filter((input) => input.required === 'true');
  };

  const canMoveFromSlide = (slide: HelpFaqContentSlide | null) => {
    if (slide && helpFaqProgress.length) {
      const requiredInputs = getRequiredInputsFromSlide(slide);
      const slideProgress = helpFaqProgress.find((s) => s.slideId === slide.id);
      const notFilledInput = requiredInputs.find(
        (input) => !slideProgress?.values?.[input.name],
      );
      return !!notFilledInput;
    }
    return false;
  };

  const handleUpdateValue = (
    name: string,
    value: string | number | boolean,
  ) => {
    updateRecentSlide({ [name]: value });
  };

  const helpFaqContantString = helpFaqData?.availableHelpFaq.content_t;
  const helpFaqContent = helpFaqContantString
    ? (JSON.parse(helpFaqContantString) as HelpFaqContentSlide)
    : null;

  const activeSlide =
    helpFaqContent && activeSlideId != null
      ? findActiveSlide(helpFaqContent, activeSlideId)
      : null;

  return (
    <HelpFaqContext.Provider
      value={{
        canMoveOn: canMoveFromSlide(activeSlide),
        helpFaqData,
        onButtonClick: handleButtonClick,
        goNext: handleGoNext,
        goBack: handleGoBack,
        onConfirm: handleConfirm,
        updateValue: handleUpdateValue,
        activeSlide,
        step: helpFaqProgress.length,
      }}
    >
      {children}
    </HelpFaqContext.Provider>
  );
};
export default HelpFaqProvider;

export const useHelpFaq = () => {
  const context = React.useContext(HelpFaqContext);
  return context;
};
