import { useQuery } from '@apollo/client';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Button } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import ConfirmDialog from '../../../base/components/ConfirmDialog/ConfirmDialog';
import { Flex } from '../../../base/components/Flex';
import { Loader } from '../../../client/components/Loader';
import {
  CompanyFilesDocument,
  InvalidateCompanyFileDocument,
} from '../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../hooks/useMutationWithSnackbar';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { TopBar } from '../../components/TopBar';
import CompanyFileCard from './components/CompanyFileCard';
import CreateCompanyFileDialog from './components/CreateCompanyFileDialog';

const CompanyFilesPage = () => {
  const { _t } = useTranslationPrefix('CompanyFilesPage');
  const { companyId } = useParams<{ companyId: string }>();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [invalidateConfirmOpen, setInvalidateConfirmOpen] = useState<
    string | null
  >(null);

  const { data, refetch, error, loading } = useQuery(CompanyFilesDocument, {
    variables: {
      input: {
        companyId: companyId,
      },
    },
    fetchPolicy: 'cache-and-network',
  });
  useQueryErrorSnackbar(error, refetch);

  const [invalidate] = useMutationWithSnackbar(
    'CompanyFilesPage.invalidateMutation',
    InvalidateCompanyFileDocument,
    {
      onCompleted: () => {
        refetch();
        setInvalidateConfirmOpen(null);
      },
    },
  );

  const handleInvalidateConfirm = async () => {
    if (invalidateConfirmOpen) {
      await invalidate({
        variables: {
          input: {
            fileId: invalidateConfirmOpen,
          },
        },
      });
    }
  };

  return loading || !data?.companyFiles ? (
    <Loader />
  ) : (
    <>
      <TopBar
        leftSideText={_t('title', 'Company files')}
        actions={
          <Button
            id="create-qr-dialog"
            variant="contained"
            onClick={() => setCreateDialogOpen(true)}
            startIcon={<AddRoundedIcon />}
          >
            {_t('createFile', 'Upload file')}
          </Button>
        }
      />

      <Flex gap={2} flexDirection="column">
        {data?.companyFiles?.map((file) => (
          <CompanyFileCard
            key={file.id}
            file={file}
            onInvalidateClick={() => setInvalidateConfirmOpen(file.id)}
          />
        ))}
      </Flex>
      <CreateCompanyFileDialog
        open={createDialogOpen}
        companyId={companyId!}
        onClose={() => setCreateDialogOpen(false)}
        onSuccess={() => {
          refetch();
          setCreateDialogOpen(false);
        }}
      />
      <ConfirmDialog
        title={_t('invalidateConfirm', 'Invalidate File?')}
        open={!!invalidateConfirmOpen}
        onClose={() => setInvalidateConfirmOpen(null)}
        onConfirm={handleInvalidateConfirm}
        confirmButtonColor="error"
        confirmLabel={_t('invalidate', 'Invalidate')}
        confirmButtonStartIcon={<DeleteForeverIcon />}
      />
    </>
  );
};

export default CompanyFilesPage;
