import { useMutation, useQuery } from '@apollo/client';
import isPropValid from '@emotion/is-prop-valid';
import EditIcon from '@mui/icons-material/Edit';
import SystemUpdateIcon from '@mui/icons-material/SystemUpdate';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { Button, CircularProgress } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import ConfirmDialog from '../../../base/components/ConfirmDialog/ConfirmDialog';
import {
  ApplicationStatus,
  EditApplicationDocument,
  GetApplicationDetailDocument,
  GetApplicationDetailQuery,
  GetApplicationVersionDocument,
  PublishApplicationVersionDocument,
} from '../../../graphql/graphql-operations';
import useMutationSnackbar from '../../../hooks/useMutationSnackbar';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { TopBar } from '../../components/TopBar';
import ApplicationEditDialog from './ApplicationEditDialog';
import ApplicationVersionDetail from './ApplicationVersionDetail';
import ApplicationVersionSelector from './ApplicationVersionSelector';

type Version = GetApplicationDetailQuery['application']['versions'][0];

const ApplicationDetailPage = () => {
  const { applicationId } = useParams<{ applicationId: string }>();
  const { t } = useTranslation();
  const [selectedVersionId, setSelectedVersionId] = useState<string>();
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
  const [unarchiveDialogOpen, setUnarchiveDialogOpen] =
    useState<boolean>(false);
  const [publishUpdateConfirmOpen, setPublishUpdateConfirmOpen] =
    useState<boolean>(false);
  const { spacing } = useTheme();

  const {
    data: appData,
    loading: appLoading,
    error: appError,
    refetch: appRefetch,
  } = useQuery(GetApplicationDetailDocument, {
    fetchPolicy: 'cache-and-network',
    variables: { app: { applicationId: applicationId } },
  });
  useQueryErrorSnackbar(appError, appRefetch);
  const application = appData?.application;

  useEffect(() => {
    //currently selected version on load is either current version or of none last uploaded one.
    if (!selectedVersionId) {
      if (application?.currentVersion?.id) {
        setSelectedVersionId(application.currentVersion.id);
        return;
      }
      const latest = application?.versions?.reduce(
        (latest, candidate) =>
          !latest || candidate.id > latest.id ? candidate : latest,
        null as unknown as Version,
      );
      if (latest) {
        setSelectedVersionId(latest.id);
      }
    }
  }, [application, selectedVersionId, setSelectedVersionId]);

  const {
    data: versionData,
    loading: versionLoading,
    error: versionError,
    refetch: versionRefetch,
  } = useQuery(GetApplicationVersionDocument, {
    variables: { applicationVersionId: selectedVersionId },
    skip: !selectedVersionId,
  });
  useQueryErrorSnackbar(versionError, versionRefetch);

  const publishHandler = useMutationSnackbar('publishApplicationVersion', {
    translationKey: 'applications.detail.publish',
    onSuccessfullyCompleted: () => setPublishUpdateConfirmOpen(false),
  });

  const [publishMutation] = useMutation(PublishApplicationVersionDocument, {
    ...publishHandler,
  });

  const handlePublishVersion = () => {
    if (versionData?.applicationVersion.id) {
      publishMutation({
        variables: {
          versionId: versionData?.applicationVersion.id,
        },
      });
    }
  };

  const unarchiveHandler = useMutationSnackbar('editApplication', {
    translationKey: 'applications.detail.unarchive',
    onSuccessfullyCompleted: () => setUnarchiveDialogOpen(false),
  });

  const [unarchiveMutation] = useMutation(EditApplicationDocument, {
    ...unarchiveHandler,
  });

  const handleUnarchiveApplication = () => {
    unarchiveMutation({
      variables: {
        app: {
          applicationId: applicationId!,
          status: ApplicationStatus.ACTIVE,
        },
      },
    });
  };

  return (
    <>
      {appLoading ? (
        <CircularProgress />
      ) : (
        <>
          <TopBar
            leftSideText={application?.name}
            leftSideStatusText={
              <ApplicationDetailPageStatus enabled={!!application?.enabled}>
                {application?.enabled
                  ? t('applications.detail.enabled', 'Enabled')
                  : t('applications.detail.disabled', 'Disabled')}
              </ApplicationDetailPageStatus>
            }
            actions={
              <>
                <ApplicationVersionSelector
                  versions={
                    application?.versions ? [...application.versions] : []
                  }
                  selectedVersionId={selectedVersionId}
                  onVersionSelected={setSelectedVersionId}
                />
                {application?.status === ApplicationStatus.ACTIVE ? (
                  <>
                    <Button
                      id="application-detail-page-edit"
                      size="large"
                      color="primary"
                      variant="contained"
                      startIcon={<EditIcon viewBox="5 5 14 14" />}
                      onClick={() => setEditDialogOpen(true)}
                    >
                      {t(
                        'applications.detail.editApplicationAction',
                        'Edit application',
                      )}
                    </Button>
                    {versionData?.applicationVersion &&
                      !versionData.applicationVersion.published && (
                        <Button
                          id="application-detail-page-publish"
                          size="large"
                          color="primary"
                          variant="contained"
                          startIcon={<SystemUpdateIcon viewBox="5 5 14 14" />}
                          onClick={() => setPublishUpdateConfirmOpen(true)}
                          style={{ marginLeft: spacing(1) }}
                        >
                          {t(
                            'applications.detail.publishVersionAction',
                            'Publish update',
                          )}
                        </Button>
                      )}
                  </>
                ) : (
                  <Button
                    id="application-detail-page-reactivate"
                    size="large"
                    color="success"
                    variant="contained"
                    startIcon={<UnarchiveIcon viewBox="5 5 14 14" />}
                    onClick={() => setUnarchiveDialogOpen(true)}
                  >
                    {t(
                      'applications.detail.unarchiveApplication',
                      'Reactivate application',
                    )}
                  </Button>
                )}
              </>
            }
          />
          {versionData && (
            <ApplicationVersionDetail
              versionData={versionData}
              isLoading={versionLoading}
            />
          )}
        </>
      )}
      <ApplicationEditDialog
        open={editDialogOpen}
        application={application}
        onClose={() => setEditDialogOpen(false)}
      />
      <ConfirmDialog
        open={publishUpdateConfirmOpen}
        title={t(
          'applications.detail.publishVersionConfirmTitle',
          'Publish update?',
        )}
        onClose={() => setPublishUpdateConfirmOpen(false)}
        onConfirm={handlePublishVersion}
      ></ConfirmDialog>
      <ConfirmDialog
        open={unarchiveDialogOpen}
        title={t(
          'applications.detail.unarchiveApplicationConfirm',
          'Reactivate application?',
        )}
        onClose={() => setUnarchiveDialogOpen(false)}
        onConfirm={handleUnarchiveApplication}
      ></ConfirmDialog>
    </>
  );
};

export default ApplicationDetailPage;

const ApplicationDetailPageStatus = styled('span', {
  shouldForwardProp: isPropValid,
})<{ enabled: boolean }>(
  ({ enabled, theme: { palette } }) => `
    color: ${enabled ? palette.success.main : palette.error.main};
`,
);
