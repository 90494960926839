import { BoxProps, styled } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { usePrevious } from 'react-use';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import { formatTime } from '../../../../lib/date';
import { formatDistance } from '../../../../lib/distance';
import Chart from '../../../components/Charts/Chart';
import { LegendSelectChangedEvent } from '../../../components/Charts/ChartTypes';

interface Props extends BoxProps {
  data: LimbMovementData[];
  view: LimbMovementView;
}

interface LimbMovementData {
  key: string;
  sceneId: string;
  sceneName: string;
  left: LimbMovementDataVector[];
  right: LimbMovementDataVector[];
}

interface LimbMovementDataVector {
  x: number;
  y: number;
  z: number;
}

export type LimbMovementView = 'back' | 'top' | 'side';
type LimbMovementSide = 'left' | 'right';
const mapView = (
  data: LimbMovementDataVector,
  view: LimbMovementView,
  side: LimbMovementSide,
): number[] => {
  switch (view) {
    case 'back':
      return [data.x, data.y];
    case 'top':
      return [data.x, data.z];
    case 'side':
      //left side is reversed
      return [side === 'left' ? -1 * data.z : data.z, data.y];
  }
};

const getViewWindow = (
  data: LimbMovementData[],
  view: LimbMovementView,
  side: LimbMovementSide,
) => {
  const margin = 0.2;
  //left side is reversed
  const zMultiplier = view === 'side' && side === 'left' ? -1 : 1;
  const countedAxis =
    view === 'side' ? ['z', 'y'] : view === 'top' ? ['x', 'z'] : ['x', 'y'];
  let minX = 0,
    maxX = 0,
    minY = 0,
    maxY = 0,
    minZ = 0,
    maxZ = 0;

  for (const series of data) {
    for (const row of series[side]) {
      minX = Math.min(row.x, minX);
      minY = Math.min(row.y, minY);
      minZ = Math.min(row.z * zMultiplier, minZ);

      maxX = Math.max(row.x, maxX);
      maxY = Math.max(row.y, maxY);
      maxZ = Math.max(row.z * zMultiplier, maxZ);
    }
  }

  let maxDiff = Math.max(
    countedAxis.includes('x') ? maxX - minX : Number.NEGATIVE_INFINITY,
    countedAxis.includes('y') ? maxY - minY : Number.NEGATIVE_INFINITY,
    countedAxis.includes('z') ? maxZ - minZ : Number.NEGATIVE_INFINITY,
  );
  maxDiff = Math.abs(maxDiff);
  maxDiff = Math.max(maxDiff, 0.2); // not too small
  maxDiff = Math.min(maxDiff, 3); // not too big
  maxDiff = maxDiff + margin;

  const xFocus = (maxX + minX) / 2;
  const yFocus = (maxY + minY) / 2;
  const zFocus = (maxZ + minZ) / 2;

  const window = {
    minX: xFocus - maxDiff / 2,
    maxX: xFocus + maxDiff / 2,
    minY: yFocus - maxDiff / 2,
    maxY: yFocus + maxDiff / 2,
    minZ: zFocus - maxDiff / 2,
    maxZ: zFocus + maxDiff / 2,
  };
  return window;
};
const prepareSeries = (
  run: LimbMovementData,
  view: LimbMovementView,
  side: LimbMovementSide,
) => {
  return {
    name: formatSeriesName(run),
    type: 'scatter',
    data: run[side].map((val) => mapView(val, view, side)),
    symbol: 'circle',
    symbolSize: 10,
    xAxisIndex: side === 'left' ? 0 : 1,
    yAxisIndex: side === 'left' ? 0 : 1,
  };
};
const formatSeriesName = (run: LimbMovementData) => {
  return formatTime(run.key) + ' ' + run.sceneName;
};

const filterSelectedSeries = (
  data: LimbMovementData[],
  unselected: string[],
) => {
  return data.filter((row) => !unselected.includes(formatSeriesName(row)));
};

//https://echarts.apache.org/examples/en/editor.html?c=grid-multiple
const LimbMovementChart = ({ data, view, ...boxProps }: Props) => {
  const [unselected, setUnselected] = useState<string[]>([]);
  const { _t } = useTranslationPrefix('LimbMovementChart');
  const prevData = usePrevious(data);

  useEffect(() => {
    setUnselected([]);
  }, [data]);

  const minMax = useMemo(
    () => ({
      left: getViewWindow(filterSelectedSeries(data, unselected), view, 'left'),
      right: getViewWindow(
        filterSelectedSeries(data, unselected),
        view,
        'right',
      ),
    }),
    [data, view, unselected],
  );
  const onSelectionChanded = useCallback((event: LegendSelectChangedEvent) => {
    const tmp = [];
    for (const key in event.selected) {
      const isSelected = event.selected[key];
      if (!isSelected) {
        tmp.push(key);
      }
    }
    setUnselected(tmp);
  }, []);

  return (
    <LimbMovementChartWrapper
      {...boxProps}
      setOptionsBehaviour={{ notMerge: data !== prevData }}
      onLegendSelectChange={onSelectionChanded}
      options={{
        legend: {
          bottom: 10,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        grid: [
          {
            containLabel: false,
            width: 400,
            height: 400,
            left: 50,
            bottom: 80,
          },
          {
            containLabel: false,
            width: 400,
            height: 400,
            right: 50,
            bottom: 80,
          },
        ],

        xAxis: [
          {
            //name: view === 'side' ? 'Y' : 'X',
            nameLocation: 'center',
            gridIndex: 0,
            type: 'value',
            min: view === 'side' ? minMax.left.minZ : minMax.left.minX,
            max: view === 'side' ? minMax.left.maxZ : minMax.left.maxX,
            axisLabel: {
              formatter: (val: number) => {
                return formatDistance(val);
              },
            },
          },
          {
            //name: view === 'side' ? 'Y' : 'X',
            nameLocation: 'center',
            gridIndex: 1,
            type: 'value',
            min: view === 'side' ? minMax.right.minZ : minMax.right.minX,
            max: view === 'side' ? minMax.right.maxZ : minMax.right.maxX,
            axisLabel: {
              formatter: (val: number) => {
                return formatDistance(val);
              },
            },
          },
        ],
        yAxis: [
          {
            //name: view === 'top' ? 'Z' : 'Y',
            nameLocation: 'center',
            gridIndex: 0,
            type: 'value',
            min: view === 'top' ? minMax.left.minZ : minMax.left.minY,
            max: view === 'top' ? minMax.left.maxZ : minMax.left.maxY,
            axisLabel: {
              formatter: (val: number) => {
                return formatDistance(val);
              },
            },
          },
          {
            //name: view === 'top' ? 'Z' : 'Y',
            nameLocation: 'center',
            gridIndex: 1,
            type: 'value',
            min: view === 'top' ? minMax.right.minZ : minMax.right.minY,
            max: view === 'top' ? minMax.right.maxZ : minMax.right.maxY,
            axisLabel: {
              formatter: (val: number) => {
                return formatDistance(val);
              },
            },
          },
        ],
        series: [
          {
            name: _t('shoulder', 'Rameno'),
            type: 'scatter',
            data: [[0, 0]],
            symbol: 'circle',
            symbolSize: 20,
            color: 'red',
            xAxisIndex: 0,
            yAxisIndex: 0,
            z: 1000,
          },
          {
            name: _t('shoulder', 'Rameno'),
            type: 'scatter',
            data: [[0, 0]],
            symbol: 'circle',
            symbolSize: 20,
            color: 'red',
            xAxisIndex: 1,
            yAxisIndex: 1,
            z: 1000,
          },
          ...data.map((run) => prepareSeries(run, view, 'left')),
          ...data.map((run) => prepareSeries(run, view, 'right')),
        ],
      }}
    />
  );
};

const LimbMovementChartWrapper = styled(Chart)`
  height: 500px;
  min-width: 950px;
`;

export default React.memo(LimbMovementChart);
