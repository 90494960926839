import { useQuery } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button, Switch, TableCell } from '@mui/material';
import { useState } from 'react';
import { useSessionStorage } from 'react-use';
import ConfirmDialog from '../../../base/components/ConfirmDialog/ConfirmDialog';
import { Flex } from '../../../base/components/Flex';
import {
  ColumnConfig,
  SimpleTable,
} from '../../../base/components/SimpleTable';
import {
  GetVrDevicesForSysUpdateDocument,
  GetVrDevicesForSysUpdateQuery,
  GetVrDevicesForSysUpdateQueryVariables,
  OrderDirection,
  SetVrDeviceOsUpdatesEnabledDocument,
  VrDeviceFilterField,
  VrDeviceOrderField,
} from '../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../hooks/useMutationWithSnackbar';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { SectionPaper } from '../../components/SectionPaper';
import { TopBar } from '../../components/TopBar';

type TableData = NonNullable<
  GetVrDevicesForSysUpdateQuery['vrDevicesPaginated']['nodes']
>[0];

const OSUpdatesPage = () => {
  const [confirm, setConfirm] = useState<'enable' | 'disable' | null>(null);
  const { _t } = useTranslationPrefix('OsUpdatesPage');
  const [rowsPerPage] = useSessionStorage('rowsPerPage', 15);

  const { data, variables, loading, error, refetch } = useQuery(
    GetVrDevicesForSysUpdateDocument,
    {
      variables: {
        first: rowsPerPage,
        orderBy: {
          field: VrDeviceOrderField.ID,
          direction: OrderDirection.ASC,
        },
        filter: {
          deleted: false,
        },
      },
    },
  );
  useQueryErrorSnackbar(error, refetch);

  const [setVrDeviceOsUpdatesEnabled, { loading: changing }] =
    useMutationWithSnackbar(
      'OsUpdatesPage.setVrDeviceOsUpdatesEnabled',
      SetVrDeviceOsUpdatesEnabledDocument,
    );

  const handleDeviceUpdateChanged =
    (vrDeviceId: string) =>
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setVrDeviceOsUpdatesEnabled({
        variables: {
          input: {
            enabled: checked,
            vrDeviceId: vrDeviceId,
          },
        },
      });
    };

  const handleDeviceUpdateChangeAll = (enabled: boolean) => async () => {
    await setVrDeviceOsUpdatesEnabled({
      variables: {
        input: {
          enabled: enabled,
        },
      },
      refetchQueries: [getOperationName(GetVrDevicesForSysUpdateDocument)!],
    });
    setConfirm(null);
  };

  const tableData: TableData[] = data?.vrDevicesPaginated?.nodes
    ? [...data.vrDevicesPaginated.nodes]
    : [];

  const columns: ColumnConfig<TableData>[] = [
    {
      id: 'id' as const,
      defaultTrans: 'ID',
    },
    {
      id: 'companyName' as const,
      defaultTrans: 'Company',
      width: '15%',
    },
    {
      id: 'name' as const,
      defaultTrans: 'Device',
      width: '15%',
    },
    {
      id: 'osVersion' as const,
      defaultTrans: 'Version',
    },
    {
      id: 'deviceOwner' as const,
      defaultTrans: 'Device Owner',
      sortable: false,
      component: ({ deviceOwner }) => {
        return (
          <TableCell sx={{ textAlign: 'center' }}>
            {deviceOwner ? (
              <CheckCircleIcon color="success" />
            ) : (
              <CancelIcon color="error" />
            )}
          </TableCell>
        );
      },
    },
    {
      id: 'osUpdatesEnabled' as const,
      defaultTrans: 'Updates',
      sortable: false,
      component: ({ id, deviceOwner, osUpdatesEnabled }) => (
        <TableCell sx={{ textAlign: 'center' }}>
          <Switch
            disabled={!deviceOwner || changing}
            checked={osUpdatesEnabled}
            onChange={handleDeviceUpdateChanged(id)}
          />
        </TableCell>
      ),
    },
  ];
  return (
    <>
      <TopBar
        leftSideText={_t('title', 'Device OS updates')}
        actions={
          <Flex gap={2}>
            <Button
              id="enable-all"
              disabled={changing}
              onClick={() => setConfirm('enable')}
            >
              {_t('enableAll', 'Enable all')}
            </Button>
            <Button
              id="disable-all"
              variant="contained"
              disabled={changing}
              onClick={() => setConfirm('disable')}
            >
              {_t('disableAll', 'Disable all')}
            </Button>
          </Flex>
        }
      />
      <SectionPaper style={{ marginTop: 2 }}>
        <SimpleTable<TableData, GetVrDevicesForSysUpdateQueryVariables>
          id="vrDevicesForUpdate"
          rowId="id"
          translationPrefix="OsUpdatesPage.table"
          isLoading={loading}
          data={tableData}
          columns={columns}
          lazyConfig={{
            refetch: refetch,
            variables: variables,
            pageCursors: data?.vrDevicesPaginated.pageCursors,
            totalCount: data?.vrDevicesPaginated.totalCount,
          }}
          searchConfig={{
            filterBy: VrDeviceFilterField.COMPANY_NAME,
            defaultPlaceholder: 'Search devices by company',
            defaultTitle: 'Devices list',
          }}
        />
      </SectionPaper>
      <ConfirmDialog
        title={_t('enableConfirm', 'Enable OS updates on all devices?')}
        open={confirm === 'enable'}
        onClose={() => setConfirm(null)}
        onConfirm={handleDeviceUpdateChangeAll(true)}
      />
      <ConfirmDialog
        title={_t('disableConfirm', 'Disable OS updates on all devices?')}
        open={confirm === 'disable'}
        onClose={() => setConfirm(null)}
        onConfirm={handleDeviceUpdateChangeAll(false)}
      />
    </>
  );
};

export default OSUpdatesPage;
