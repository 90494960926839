import { BoxProps, styled } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { usePrevious } from 'react-use';
import { formatTime } from '../../../../lib/date';
import { formatDistance } from '../../../../lib/distance';
import Chart from '../../../components/Charts/Chart';
import { LegendSelectChangedEvent } from '../../../components/Charts/ChartTypes';

interface Props extends BoxProps {
  data: HeadStatokinesiogramChartData[];
  view: HeadStatokinesiogramChartView;
}

interface HeadStatokinesiogramChartData {
  key: string;
  sceneId: string;
  sceneName: string;
  data: HeadStatokinesiogramChartDataVector[];
}

interface HeadStatokinesiogramChartDataVector {
  x: number;
  y: number;
  z: number;
  time: number;
}

export type HeadStatokinesiogramChartView = 'top' | 'back' | 'side';
const mapView = (
  data: HeadStatokinesiogramChartDataVector,
  view: HeadStatokinesiogramChartView,
): number[] => {
  switch (view) {
    case 'top':
      return [data.x, data.z];
    case 'back':
      return [data.x, data.y];
    case 'side':
      return [data.z, data.y];
  }
};

const getViewWindow = (
  data: HeadStatokinesiogramChartData[],
  ratio: number,
  rationAxis: ('x' | 'y' | 'z')[],
) => {
  const margin = 0.1;
  const xRatio = rationAxis[0] === 'x' ? ratio : 1;
  const yRatio = rationAxis[0] === 'y' ? ratio : 1;
  const zRatio = rationAxis[0] === 'z' ? ratio : 1;

  let minX = 0,
    maxX = 0,
    minY = 0,
    maxY = 0,
    minZ = 0,
    maxZ = 0;

  for (const series of data) {
    for (const row of series.data) {
      minX = Math.min(row.x, minX);
      minY = Math.min(row.y, minY);
      minZ = Math.min(row.z, minZ);

      maxX = Math.max(row.x, maxX);
      maxY = Math.max(row.y, maxY);
      maxZ = Math.max(row.z, maxZ);
    }
  }

  let maxDiff = Math.max(
    rationAxis.includes('x')
      ? (maxX - minX) / xRatio
      : Number.NEGATIVE_INFINITY,
    rationAxis.includes('y')
      ? (maxY - minY) / yRatio
      : Number.NEGATIVE_INFINITY,
    rationAxis.includes('z')
      ? (maxZ - minZ) / zRatio
      : Number.NEGATIVE_INFINITY,
  );
  maxDiff = Math.abs(maxDiff);
  maxDiff = Math.max(maxDiff, 0.2); // not too small
  maxDiff = Math.min(maxDiff, 3); // not too big
  maxDiff = maxDiff + margin;

  const xFocus = (maxX + minX) / 2;
  const yFocus = (maxY + minY) / 2;
  const zFocus = (maxZ + minZ) / 2;

  const window = {
    minX: xFocus - (maxDiff / 2) * xRatio,
    maxX: xFocus + (maxDiff / 2) * xRatio,
    minY: yFocus - (maxDiff / 2) * yRatio,
    maxY: yFocus + (maxDiff / 2) * yRatio,
    minZ: zFocus - (maxDiff / 2) * zRatio,
    maxZ: zFocus + (maxDiff / 2) * zRatio,
  };
  return window;
};

const formatSeriesName = (run: HeadStatokinesiogramChartData) => {
  return formatTime(run.key) + ' ' + run.sceneName;
};
const prepareSeries = (
  run: HeadStatokinesiogramChartData,
  view: HeadStatokinesiogramChartView,
) => {
  return {
    name: formatSeriesName(run),
    type: 'scatter',
    data: run.data.map((val) => mapView(val, view)),
    symbol: 'circle',
    symbolSize: 5,
  };
};

const filterSelectedSeries = (
  data: HeadStatokinesiogramChartData[],
  unselected: string[],
) => {
  return data.filter((row) => !unselected.includes(formatSeriesName(row)));
};

//https://echarts.apache.org/examples/en/editor.html?c=grid-multiple
const HeadStatokinesiogramChartChart = ({ data, view, ...boxProps }: Props) => {
  const [unselected, setUnselected] = useState<string[]>([]);
  const prevData = usePrevious(data);
  const width = 898;
  const height = 390;

  useEffect(() => {
    setUnselected([]);
  }, [data]);

  const minMax = useMemo(
    () =>
      getViewWindow(
        filterSelectedSeries(data, unselected),
        width / height,
        view === 'side' ? ['z', 'y'] : view === 'top' ? ['x', 'z'] : ['x', 'y'],
      ),
    [data, view, unselected],
  );

  const onSelectionChanded = useCallback((event: LegendSelectChangedEvent) => {
    const tmp = [];
    for (const key in event.selected) {
      const isSelected = event.selected[key];
      if (!isSelected) {
        tmp.push(key);
      }
    }
    setUnselected(tmp);
  }, []);

  return (
    <HeadStatokinesiogramChartPageWrapper
      {...boxProps}
      setOptionsBehaviour={{ notMerge: data !== prevData }}
      onLegendSelectChange={onSelectionChanded}
      options={{
        legend: {
          bottom: 0,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        grid: [
          {
            containLabel: false,
            width: width,
            height: height,
            left: 100,
            bottom: 80,
          },
        ],
        xAxis: [
          {
            gridIndex: 0,
            type: 'value',
            axisLabel: {
              formatter: (val: number) => {
                return formatDistance(val, ' ', true);
              },
            },
            min: view === 'side' ? minMax.minZ : minMax.minX,
            max: view === 'side' ? minMax.maxZ : minMax.maxX,
          },
        ],
        yAxis: [
          {
            gridIndex: 0,
            type: 'value',
            axisLabel: {
              formatter: (val: number) => {
                return formatDistance(val, ' ', true);
              },
            },
            min: view === 'top' ? minMax.minZ : minMax.minY,
            max: view === 'top' ? minMax.maxZ : minMax.maxY,
          },
        ],
        series: [...data.map((run) => prepareSeries(run, view))],
      }}
    />
  );
};

const HeadStatokinesiogramChartPageWrapper = styled(Chart)`
  width: 1000px;
  height: 500px;
  margin-left: auto;
  margin-right: auto;
`;

export default React.memo(HeadStatokinesiogramChartChart);
