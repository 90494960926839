import { Button, MenuItem } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { BaseDialog } from '../../../../base/components/BaseDialog';
import { Flex } from '../../../../base/components/Flex';
import FormikFileSelect from '../../../../base/components/form/FormikFileSelect';
import FormikForm from '../../../../base/components/form/FormikForm';
import FormikSelect from '../../../../base/components/form/FormikSelect';
import FormikTextField from '../../../../base/components/form/FormikTextField';
import {
  CompanyFileType,
  CreateCompanyFileDocument,
} from '../../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../../hooks/useMutationWithSnackbar';
import {
  TFunctionPrefixed,
  useTranslationPrefix,
} from '../../../../hooks/useTranslationPrefix';
import { MAX_UPLOAD_FILE_SIZE } from '../../../../lib/constants';

export const SUPPORTED_FORMATS = ['image/jpeg', 'image/png'];

interface FormData {
  file: File | null;
  name: string;
  type: CompanyFileType;
}

interface Props {
  companyId: string;
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

const CreateCompanyFileDialog = ({
  open,
  companyId,
  onClose,
  onSuccess,
}: Props) => {
  const { _t, t } = useTranslationPrefix('CompanyFilesPage');

  const [createMutation] = useMutationWithSnackbar(
    'CompanyFilesPage.createMutation',
    CreateCompanyFileDocument,
    {
      onCompleted: () => {
        onSuccess?.();
      },
    },
  );

  const handleSubmit = async (
    values: FormData,
    helpers: FormikHelpers<FormData>,
  ) => {
    await createMutation({
      variables: {
        input: {
          companyId: companyId,
          name: values.name,
          type: values.type,
        },
        file: values.file,
      },
    });
  };

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title={_t('dialog.title', 'Add new QR code')}
      disableEnforceFocus
    >
      <>
        <Formik<FormData>
          initialValues={{
            file: null,
            name: '',
            type: CompanyFileType.IMAGE,
          }}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={getValidationSchema(_t)}
        >
          {({ isSubmitting }) => (
            <FormikForm>
              <Flex flexDirection="column" gap={3}>
                <FormikFileSelect
                  name="file"
                  label={_t(`file.label`, 'Add file')}
                  accept={SUPPORTED_FORMATS.join(', ')}
                  disabled={isSubmitting}
                  color="primary"
                />
                <FormikTextField
                  fullWidth
                  name="name"
                  label={_t('name.label', 'Name')}
                />
                <FormikSelect
                  fullWidth
                  name="type"
                  label={_t('type.label', 'File type')}
                >
                  <MenuItem value={CompanyFileType.IMAGE}>
                    {t(
                      `enum.CompanyFileType.${CompanyFileType.IMAGE}`,
                      'Image',
                    )}
                  </MenuItem>
                </FormikSelect>

                <Flex
                  justifyContent="center"
                  mt={1}
                  gap={3}
                  flexDirection={{ xs: 'column-reverse', md: 'row' }}
                >
                  <Button
                    id="create-playlist-folder-cancel"
                    onClick={onClose}
                    color="primary"
                    variant="outlined"
                    disabled={isSubmitting}
                  >
                    {_t('dialog.cancel', 'Cancel')}
                  </Button>
                  <Button
                    id="create-playlist-folder-confirm"
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                  >
                    {_t('dialog.submit', 'Save')}
                  </Button>
                </Flex>
              </Flex>
            </FormikForm>
          )}
        </Formik>
      </>
    </BaseDialog>
  );
};

export default CreateCompanyFileDialog;

const getValidationSchema = (_t: TFunctionPrefixed) =>
  Yup.object({
    file: Yup.mixed()
      .required(_t('file.required', 'File is required'))
      .test(
        'fileSize',
        _t('file.fileTooLarge', 'File is too large. Maximum is 10 MB'),
        (value) => {
          if (!value) {
            return true;
          }
          return value.size <= MAX_UPLOAD_FILE_SIZE;
        },
      )
      .test(
        'fileType',
        _t('file.fileTypeForbidden', 'This file type is not supported.'),
        (value) => {
          if (!value) {
            return true;
          }
          return SUPPORTED_FORMATS.includes(value?.type);
        },
      ),
    name: Yup.string().required(
      _t('name.required', 'Name for file is required'),
    ),
    type: Yup.string().required(_t('type.required', 'File type is required')),
  });
