import { useQuery } from '@apollo/client';
import { ResponsiveStyleValue, SxProps, Theme } from '@mui/system';
import { useMemo } from 'react';
import { adminState } from '../../../../adminState';
import { BaseTypography } from '../../../../base/components/BaseTypography';
import { useUser } from '../../../../base/components/UserProvider/useUserHook';
import {
  GetPlaylistTemplatesDocument,
  GetPlaylistTemplatesQueryVariables,
  OrderDirection,
  PlaylistTemplateListFragmentFragment,
  PlaylistTemplateOrderField,
  UserRole,
} from '../../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../../hooks/useQueryErrorSnackbar';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import { joinStrings } from '../../../../lib/strings';
import { ListView, SearchListVariables } from '../../../components/ListView';
import PlaylistTemplateCard from './PlaylistTemplateCard';

interface Props {
  selectedIds?: string[];
  onClick?: (val: PlaylistTemplateListFragmentFragment) => void;
  columns?: ResponsiveStyleValue<number>;
  searchConfig?: SearchListVariables<PlaylistTemplateListFragmentFragment>;
  templatesPerPage?: number;
  ignoreIds?: string[];
  sx?: SxProps<Theme>;
  folderId?: string;
  isFolderDetail?: boolean;
  onPlaylistTemplateRemove?: (playlistTemplateId: string) => void;
}

const PlaylistTemplatesList = ({
  selectedIds,
  onClick,
  columns = { xs: 1, sm: 2, md: 3, lg: 4 },
  searchConfig,
  templatesPerPage = 30,
  ignoreIds,
  folderId,
  onPlaylistTemplateRemove,
  sx,
  isFolderDetail,
}: Props) => {
  const { _t } = useTranslationPrefix('PlayListTemplatesList');
  const { user } = useUser();
  const companyId = adminState.selectedCompanyId.get();

  const vars: GetPlaylistTemplatesQueryVariables = useMemo(
    () => ({
      orderBy: {
        field: PlaylistTemplateOrderField.NAME,
        direction: OrderDirection.ASC,
      },
      filter: {
        companyId,
        ownerId: user?.id || null,
        enabled: true,
        ignoreIds: ignoreIds,
        folderId,
      },
      first: templatesPerPage,
    }),
    [companyId, user, templatesPerPage, ignoreIds, folderId],
  );

  const { data, refetch, variables, error, loading, fetchMore } = useQuery(
    GetPlaylistTemplatesDocument,
    {
      variables: vars,
      fetchPolicy: 'cache-and-network',
    },
  );
  useQueryErrorSnackbar(error, refetch);

  const templates: PlaylistTemplateListFragmentFragment[] = data
    ?.playlistTemplates?.nodes
    ? [...data.playlistTemplates.nodes]
    : [];

  const renderValue = (template: PlaylistTemplateListFragmentFragment) => {
    return (
      <PlaylistTemplateCard
        template={template}
        onClick={onClick}
        onRemove={onPlaylistTemplateRemove}
        selected={selectedIds?.includes(template.id)}
      >
        <BaseTypography variant="body3" color="grey.500">
          {_t('createdBy', 'Created by: {{ name }}', {
            name:
              template.owner.role === UserRole.SUPER_ADMIN
                ? 'VR Admin'
                : joinStrings(
                    ' ',
                    template.owner.firstName,
                    template.owner.lastName,
                  ),
          })}
        </BaseTypography>
      </PlaylistTemplateCard>
    );
  };

  return (
    <ListView<
      PlaylistTemplateListFragmentFragment,
      GetPlaylistTemplatesQueryVariables
    >
      data={templates}
      sx={sx}
      rowKey="id"
      renderValue={renderValue}
      loading={loading}
      hideEmptyMessage={isFolderDetail}
      lazyConfig={{
        fetchMore: fetchMore,
        refetch: refetch,
        variables: variables,
        pageCursors: data?.playlistTemplates.pageCursors,
        totalCount: data?.playlistTemplates.totalCount,
      }}
      columns={columns}
      searchConfig={searchConfig}
    />
  );
};

export default PlaylistTemplatesList;
